import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    public url = 'http://cars.devcherish.site/';
    //public url = 'http://localhost:3000/';

    constructor(public http: HttpClient, public translate:TranslateService) { }

    get(path: string) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['auth-token'] = localStorage.getItem('token');
            }

            this.http.get(url, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    post(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['auth-token'] = localStorage.getItem('token');
            }

            this.http.post(url, data, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    patch(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['auth-token'] = localStorage.getItem('token');
            }

            this.http.patch(url, data, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    delete(path) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            let headers = {headers:{}};
            if(localStorage.getItem('token')){
                headers.headers['auth-token'] = localStorage.getItem('token');
            }

            this.http.delete(url, headers)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }
}